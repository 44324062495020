<template>
	<ConfirmDialog></ConfirmDialog>

	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="bm_unite" :options="CrmDataTable_options" :isHeaderVisible="true" :isNewButtonVisible="isSeviyeA" @onRowSelect="onRowSelect" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["bm_kurulummusteriidname", "bm_frmno", "bm_serino", "bm_po", "bm_urunidname", "bm_siparisnoidname", "bm_teklifnoidname", "bm_markaname", "bm_satisyapanfirmaname", "bm_turuname"],
            },
		}
	},
	created() {
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Tezgahlar');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
		}
	},
    mounted() {
        
    },
	methods: {
		onRowSelect(data) {
			console.log(data);
			//this.$router.push({ name: 'bm_unite', params: { id: data.entityId } });

			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_unite', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		}
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		},
		isSeviyeA(){
			if (this.profileData) {
				if (this.profileData.guvenlikSeviyeleri) {
					let isSeviyeA = (this.profileData.guvenlikSeviyeleri.indexOf("Seviye A") > -1);
					return isSeviyeA;
				}
			}

			return false;
		},
	}
}
</script>

<style lang="scss" scoped>

</style>
